<template>
    <div>
        <v-form lazy-validation ref="productsForm" v-model="validForm">
            <v-layout row pt-6>
                <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.status') }}</v-flex>
                <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row">
                    <v-select
                        :items="statusOptions"
                        :rules="[...validationRules.required]"
                        dense
                        hide-details="auto"
                        require
                        solo
                        v-model="Supplier__supplierstatus_id"
                        @change="$emit('fields-changed')"
                    />
                </v-flex>
            </v-layout>
            <v-layout row py-2>
                <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.typeSupplier') }}</v-flex>
                <v-flex xs6 lg4 md5 py-1 class="d-flex flex-row">
                    <v-radio-group
                        :rules="[...validationRules.required]"
                        class="mt-0"
                        dense
                        hide-details="auto"
                        row
                        v-model="type"
                        @change="$emit('fields-changed'); updateSupplierType($event)"
                    >
                        <v-radio :label="$t('message.import')" value="import"></v-radio>
                        <v-radio :label="$t('message.export')" value="export"></v-radio>
                        <v-radio :label="$t('message.both')" value="both"></v-radio>
                    </v-radio-group>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.origin') }}</v-flex>
                <v-flex xs6 lg3 md5 py-1 pl-4>
                    <v-autocomplete
                        :items="allCountries"
                        :rules="[...validationRules.required]"
                        chips
                        clearable
                        deletable-chips
                        dense
                        hide-details="auto"
                        item-text="Country.name"
                        item-value="Country.id"
                        multiple
                        required
                        solo
                        v-model="Product__origins"
                        @change="$emit('fields-changed')"
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                :input-value="data.selected"
                                class="my-1"
                                close
                                small
                                v-bind="data.attrs"
                                @click="data.select"
                                @click:close="removeOption(data.item,'Product__origins')"
                            >
                                {{ data.item.Country.name }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-flex>
            </v-layout>
            <div class="d-flex" :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'">
                <div class="no-gutter" :class="$vuetify.breakpoint.xs ? 'col-12' : 'col-5'">
                    <v-layout row pt-3>
                        <v-flex pl-5 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-5'">{{ $t('message.products') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7 pr-7 pl-0'">
                            <v-autocomplete
                                :items="allProductGroups"
                                :rules="[...validationRules.required]"
                                chips
                                clearable
                                deletable-chips
                                dense
                                hide-details="auto"
                                item-text="ProductGroup.title"
                                item-value="ProductGroup.id"
                                multiple
                                required
                                solo
                                v-model="Product__products"
                                @change="resetOptions('Product__products'); $emit('fields-changed')"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        :input-value="data.selected"
                                        class="my-1"
                                        close
                                        small
                                        v-bind="data.attrs"
                                        @click="data.select"
                                        @click:close="removeOption(data.item,'Product__products')"
                                    >
                                        {{ data.item.ProductGroup.title }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex pl-5 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-5'">{{ $t('message.species') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7 pr-7 pl-0'">
                            <v-autocomplete
                                :items="allSpecies"
                                :rules="[...validationRules.required]"
                                chips
                                clearable
                                deletable-chips
                                dense
                                hide-details="auto"
                                item-text="Species.title"
                                item-value="Species.id"
                                multiple
                                required
                                solo
                                v-model="Product__species"
                                @change="resetOptions('Product__species'); $emit('fields-changed')"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        :input-value="data.selected"
                                        class="my-1"
                                        close
                                        small
                                        v-bind="data.attrs"
                                        @click="data.select"
                                        @click:close="removeOption(data.item,'Product__species')"
                                    >
                                        {{ data.item.Species.title }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex pl-5 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-5'">{{ $t('message.grades') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7 pr-7 pl-0'">
                            <v-autocomplete
                                :items="allGrades"
                                chips
                                clearable
                                deletable-chips
                                dense
                                hide-details="auto"
                                item-text="Grade.title"
                                item-value="Grade.id"
                                multiple
                                required
                                solo
                                v-model="Product__grades"
                                @change="resetOptions('Product__grades'); $emit('fields-changed')"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        :input-value="data.selected"
                                        class="my-1"
                                        close
                                        small
                                        v-bind="data.attrs"
                                        @click="data.select"
                                        @click:close="removeOption(data.item,'Product__grades')"
                                    >
                                        {{ data.item.Grade.title }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex pl-5 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-5'">{{ $t('message.certifications') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7 pr-7 pl-0'">
                            <v-autocomplete
                                :items="allCertifications"
                                chips
                                clearable
                                deletable-chips
                                dense
                                hide-details="auto"
                                item-text="Certification.title"
                                item-value="Certification.id"
                                multiple
                                required
                                solo
                                v-model="Product__certifications"
                                @change="resetOptions('Product__certifications'); $emit('fields-changed')"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        :input-value="data.selected"
                                        class="my-1"
                                        close
                                        small
                                        v-bind="data.attrs"
                                        @click="data.select"
                                        @click:close="removeOption(data.item.Certification.id,'Product__certifications')"
                                    >
                                        {{ data.item.Certification.title }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </div>
                <div class="no-gutter" :class="$vuetify.breakpoint.xs ? 'col-12' : 'col-5'">
                    <v-layout row pt-3>
                        <v-flex pl-10 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-3'">{{ $t('message.thickness') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7'">
                            <v-lazy>
                                <v-textarea
                                    :value="Product__product_thicknesses"
                                    auto-grow
                                    class="force-text-left"
                                    clearable
                                    hide-details="auto"
                                    rows="1"
                                    solo
                                    @change="Product__product_thicknesses = $event; $emit('fields-changed')"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex pl-10 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-3'">{{ $t('message.width') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7'">
                            <v-lazy>
                                <v-textarea
                                    :value="Product__product_widths"
                                    auto-grow
                                    class="force-text-left"
                                    clearable
                                    hide-details="auto"
                                    rows="1"
                                    solo
                                    @change="Product__product_widths = $event; $emit('fields-changed')"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex pl-10 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-3'">{{ $t('message.length') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7'">
                            <v-lazy>
                                <v-textarea
                                    :value="Product__product_lengths"
                                    auto-grow
                                    class="force-text-left"
                                    clearable
                                    hide-details="auto"
                                    rows="1"
                                    solo
                                    @change="Product__product_lengths = $event; $emit('fields-changed')"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex pl-10 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-3'">{{ $t('message.mc') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7'">
                            <v-autocomplete
                                :items="allMoistureContent"
                                chips
                                clearable
                                deletable-chips
                                dense
                                hide-details="auto"
                                item-text="Mc.title"
                                item-value="Mc.id"
                                multiple
                                required
                                solo
                                v-model="Product__mcs"
                                @change="resetOptions('Product__mcs'); $emit('fields-changed')"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        :input-value="data.selected"
                                        class="my-1"
                                        close
                                        small
                                        v-bind="data.attrs"
                                        @click="data.select"
                                        @click:close="removeOption(data.item,'Product__mcs')"
                                    >
                                        {{ data.item.Mc.title }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
            <div class="d-flex py-6" :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'">
                <div class="no-gutter" :class="$vuetify.breakpoint.xs ? 'col-12' : 'col-5'">
                    <v-layout row>
                        <v-flex pl-5 py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-5'">{{ $t('message.comments') }}</v-flex>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7 pr-7 pl-0'">
                            <v-lazy>
                                <v-textarea
                                    :value="Product__comments"
                                    auto-grow
                                    class="force-text-left"
                                    clearable
                                    hide-details="auto"
                                    rows="1"
                                    solo
                                    @change="Product__comments = $event; $emit('fields-changed')"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
            <div class="d-flex py-3" :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'">
                <div class="no-gutter" :class="$vuetify.breakpoint.xs ? 'col-12' : 'col-5'">
                    <v-layout row>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-5'"/>
                        <v-flex py-1 :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-7 pr-7 pl-0'">
                            <v-btn
                                :loading="saving"
                                class="px-3"
                                color="info"
                                width="50"
                                small
                                @click="saving = true; $emit('save-supplier')"
                                v-if="updateMode"
                            >
                                {{ $t('message.save') }}
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>

import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapGetters, mapActions} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
    name: "SupplierProductsV2",
    props: ['updateMode','validate','changesSaved'],
    data() {
        return {
            saving: false,
            statusOptions: [
                {'value': 1, 'text': this.$t('message.activated')},
                {'value': 2, 'text': this.$t('message.potential')},
                {'value': 0, 'text': this.$t('message.suspended')}
            ],
            type: null,
            validForm: false
        }
    },
    computed: {
        statePrefix(){
            if(this.updateMode){
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('supplier',{
            Product__certifications: 'Product.certifications',
            Product__comments: 'Product.comments',
            Product__grades: 'Product.grades',
            Product__mcs: 'Product.mcs',
            Product__origins: 'Product.origins',
            Product__products: 'Product.products',
            Product__species: 'Product.species',
            Product__product_lengths: 'Product.product_lengths',
            Product__product_thicknesses: 'Product.product_thicknesses',
            Product__product_widths: 'Product.product_widths',
            Product__is_export: 'Product.is_export',
            Product__is_import: 'Product.is_import',
            Supplier__supplierstatus_id: 'Supplier.supplierstatus_id'
        }, 'statePrefix'),
        ...mapFields('supplier',{
            options: 'options'
        }),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades'
        }),
        ...mapGetters('mc',{
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('species',{
            allSpecies: 'allSpecies'
        }),
        ...mapGetters([
            'validationRules'
        ]),
    },
    methods: {
        ...mapActions('certification', {
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('country', {
            getAllCountries: 'getAllCountries'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        resetOptions(productSpecName) {
            let optionKey = null
            switch(productSpecName){
                case 'Product__certifications':
                    optionKey = 'certifications'
                    break
                case 'Product__grades':
                    optionKey = 'grades'
                    break
                case 'Product__mcs':
                    optionKey = 'mcs'
                    break
                case 'Product__products':
                    optionKey = 'products'
                    break
                case 'Product__species':
                    optionKey = 'species'
                    break
            }
            if(optionKey){
                this.options[optionKey] = []
            }
            return true
        },
        removeOption(optionId, productSpecName) {
            try {
                const index = this[productSpecName].indexOf(optionId)
                this[productSpecName].splice(index,1)
                let optionKey = null
                switch(productSpecName){
                    case 'Product__certifications':
                        optionKey = 'certifications'
                        break
                    case 'Product__grades':
                        optionKey = 'grades'
                        break
                    case 'Product__mcs':
                        optionKey = 'mcs'
                        break
                    case 'Product__products':
                        optionKey = 'products'
                        break
                    case 'Product__species':
                        optionKey = 'species'
                        break
                }
                if(optionKey){
                    this.options[optionKey] = []
                }
                return true
            } catch {
                return false
            }
        },
        updateSupplierType(value) {
            console.log(value)
            if(value == 'import') {
                this.Product__is_import = 1
                this.Product__is_export = null
            } else if(value == 'export') {
                this.Product__is_import = null
                this.Product__is_export = 1
            } else if(value == 'both'){
                this.Product__is_import = 1
                this.Product__is_export = 1
            }
        }
    },
    watch: {
        changesSaved(value) {
            if(value) this.saving = false
        },
        // type(value){
        //     console.log(value)
        //     if(value == 'import') {
        //         this.Product__is_import = 1
        //         this.Product__is_export = null
        //     } else if(value == 'export') {
        //         this.Product__is_import = null
        //         this.Product__is_export = 1
        //     } else if(value == 'both'){
        //         this.Product__is_import = 1
        //         this.Product__is_export = 1
        //     }
        // },
        resetStore(store) {
            this.options[store] = []
        },
        validate(value) {
            if(value){
                let valid = this.$refs.productsForm.validate()
                if(valid) {
                    this.$emit('fields-valid')
                } else {
                    this.$emit('fields-invalid')
                }
            }
        }
    },
    created() {
        if(this.Product__is_export == 1 && this.Product__is_import == null) this.type = 'export'
        if(this.Product__is_export == null && this.Product__is_import == 1) this.type = 'import'
        if(this.Product__is_export == 1 && this.Product__is_import == 1) this.type = 'both'
    },
    mounted() {
        // this.$nextTick(() => {
        let _this = this
        setTimeout(function(){
            if(_this.Product__is_export == 1 && _this.Product__is_import == null) _this.type = 'export'
            if(_this.Product__is_export == null && _this.Product__is_import == 1) _this.type = 'import'
            if(_this.Product__is_export == 1 && _this.Product__is_import == 1) _this.type = 'both'
            console.log(_this.type)
        }, 500)
        // if(this.Product__is_export == 1 && this.Product__is_import == null) this.type = 'export'
        // if(this.Product__is_export == null && this.Product__is_import == 1) this.type = 'import'
        // if(this.Product__is_export == 1 && this.Product__is_import == 1) this.type = 'both'
        // })
    }
}

</script>

<style scoped>
.force-text-left .v-text-field__slot input {
    text-align: left !important;
}
.v-input__control{
    min-height: 40px !important;
}
</style>